<template>
    <!-- 摄影师列表组件 -->
    <div class="member-list-box">
        <!-- 斜杠青年 -->
        <div class="member-list-slash-people"
            v-if="itemData.versatile == 1">
            <img :src="require('@/assets/images/lensman/dcxzsg26.png')" alt="">
        </div>
        <!-- 头像 -->
        <div class="member-list-avatar-box">
            <el-image
                :src="itemData.avatar"
                fit="cover"
                lazy
                class="member-list-avatar">
                <!-- 加载中样式 -->
                <template v-slot:placeholder>
                    <div class="image-slot">
                        <i class="el-icon-loading"></i>
                    </div>
                </template>
                <!-- 加载失败样式 -->
                <template v-slot:error>
                    <div class="image-slot">
                        <i class="el-icon-picture-outline"></i>
                    </div>
                </template>
            </el-image>
        </div>
        <!-- 昵称 -->
        <div>{{itemData.name}}</div>
        <!-- 地址 -->
        <div class="member-list-address">
            <span>{{itemData.address || "-" }}</span>
        </div>
        <!-- 作品预览 -->
        <div class="member-list-works">
            <el-row :gutter="10">
                <el-col :span="8" v-for="item in itemData.works" :key="item.id">
                    <div class="member-list-work-box">
                        <el-image
                            :src="item"
                            fit="cover"
                            lazy
                            class="member-list-work-item">
                            <!-- 加载中样式 -->
                            <template v-slot:placeholder>
                                <div class="image-slot">
                                    <i class="el-icon-loading"></i>
                                </div>
                            </template>
                            <!-- 加载失败样式 -->
                            <template v-slot:error>
                                <div class="image-slot">
                                    <i class="el-icon-picture-outline"></i>
                                </div>
                            </template>
                        </el-image>
                    </div>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
    props:[ "itemData" ],
}
</script>

<style scoped>
.member-list-box{
    position: relative;
    padding-top: 20px;
}
.member-list-box>div{
    text-align: center;
}
.member-list-slash-people{
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
}
.member-list-slash-people>img{
    width: 100%;
}
/* 头像 */
.member-list-avatar{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
}
/* 地址 */
.member-list-address{
    margin-top: 5px;
    color: #909399;
}
.member-list-address>span+span{
    margin-left: 5px;
}
/* 作品预览 */
.member-list-works{
    padding: 20px;
}
.member-list-work-box{
    position: relative;
    width: 100%;
}
.member-list-work-box::before{
    display: block;
    content: "";
    padding-top: 100%;
}
.member-list-work-item{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 100%;
    height: 100%;
}
</style>
